import { motion } from "framer-motion";
import Backdrop from "./Backdrop";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaCalendar, FaClock } from "react-icons/fa";
import formatDate from "../../utils/formatDate";
import formatTime from "../../utils/formatTime";

const dropIn = {
	hidden: {
		y: "-100vh",
		opacity: 0,
	},
	visible: {
		y: "0",
		opacity: 1,
		transition: {
			duration: 0.1,
			type: "spring",
			damping: 25,
			stiffness: 500,
		},
	},
	exit: {
		y: "100vh",
		opacity: 0,
	},
};

const EventModal = ({ handleClose, item }) => {
	const { ImgURL, EventDate, EventMonth, EventName, FromEventDate, ToEventDate } = item;

	return (
		<>
			<Backdrop onClick={handleClose}>
				<motion.div className="modal fade show" onClick={(e) => e.stopPropagation()} variants={dropIn} initial="hidden" animate="visible" exit="exit">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div style={{ padding: "20px 20px", textAlign: "right", background: "#efefef", marginBottom: 10 }}>
								<IoMdCloseCircleOutline size="2em" onClick={handleClose} />
							</div>
							<div className="testimonial-area py-3">
								<div className="container">
									<div className="row align-items-center">
										<div className="col-lg-5">
											<div className="testimonial-img text-center">
												<img src={ImgURL} alt="item" />
											</div>
										</div>
										<div className="col-lg-7">
											<div className="testimonials-conetent pt-5 pt-lg-0">
												<h3 className="pb-2">{EventName}</h3>
												<p className="pb-2" style={{ fontSize: "20px" }}>
													<FaCalendar size="1em" style={{ margin: "0 10 0 0" }} />
													Date: {formatDate(FromEventDate)} - {formatDate(ToEventDate)}
												</p>
												<p style={{ fontSize: "20px" }}>
													<FaClock size="1em" style={{ margin: "0 10 0 0" }} />
													Time: {formatTime(EventDate)}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</motion.div>
			</Backdrop>
		</>
	);
};

export default EventModal;
