import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const variants = {
	visible: {
		opacity: 1,
		scale: 1,
		transition: { delay: 0.2, type: "spring", duration: 1 },
	},
	hidden: { opacity: 0, scale: 0 },
};

const CategoryCard = ({ category }) => {
	const { ID, CategDescription, CategName, CategImgURL } = category;

	return (
		<motion.div initial="hidden" whileInView="visible" variants={variants} className="col-lg-3 col-md-4 col-sm-6">
			<div className="single-courses">
				<div className="courses-main-img">
					<img src={CategImgURL} alt={CategName} />
				</div>
				<div className="courses-content">
					<h3>{CategName}</h3>
				</div>
				<div className="courses-hover-content">
					<div className="sk">
						<div style={{ height: "100%" }}>
							<h3 style={{ height: "100%" }}>
								<Link className="d-flex align-items-center" to={`/categories/${ID}/${CategName}`} style={{ height: "100%" }}>
									{CategDescription}
								</Link>
							</h3>
						</div>
					</div>
				</div>
			</div>
		</motion.div>
	);
};

export default CategoryCard;
