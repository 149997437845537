import { motion } from "framer-motion";
import Backdrop from "./Backdrop";
import Website from "./Icons/Website";
import Phone from "./Icons/Phone";
import Instagram from "./Icons/Instagram";
import Location from "./Icons/Location";
import { IoMdCloseCircleOutline } from "react-icons/io";

const dropIn = {
	hidden: {
		y: "-100vh",
		opacity: 0,
	},
	visible: {
		y: "0",
		opacity: 1,
		transition: {
			duration: 0.1,
			type: "spring",
			damping: 25,
			stiffness: 500,
		},
	},
	exit: {
		y: "100vh",
		opacity: 0,
	},
};

const Modal = ({ handleClose, item }) => {
	const { ItemOffer, ItemName, ImgURL, SocialContact } = item;

	return (
		<>
			<Backdrop onClick={handleClose}>
				<motion.div className="modal fade show" onClick={(e) => e.stopPropagation()} variants={dropIn} initial="hidden" animate="visible" exit="exit">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div style={{ padding: "20px 20px", textAlign: "right", background: "#efefef", marginBottom: 10 }}>
								<IoMdCloseCircleOutline size="2em" onClick={handleClose} />
							</div>
							<div className="testimonial-area py-3">
								<div className="container">
									<div className="row align-items-center">
										<div className="col-lg-5">
											<div className="testimonial-img text-center">
												<img src={ImgURL} alt="item" />
											</div>
										</div>
										<div className="col-lg-7">
											<div className="testimonials-conetent pt-5 pt-lg-0">
												<h3>{ItemName}</h3>
												<div className="single-testimonial py-4">
													<div className="testimonial-conetent">
														{ItemOffer && typeof ItemOffer === "string" ? (
															ItemOffer.split("\n").map((line, index) => <p key={index}>{line}</p>)
														) : (
															<p>No offer details available</p>
														)}
													</div>
													<div className="testimonial-info d-flex justify-content-center align-items-center gap-3">
														{SocialContact.website ? (
															<a href={SocialContact.website} target="_blank" rel="noreferrer">
																<Website />
															</a>
														) : null}
														{SocialContact.instagram ? (
															<a href={SocialContact.instagram} target="_blank" rel="noreferrer">
																<Instagram />
															</a>
														) : null}
														{SocialContact.locationURL ? (
															<a href={SocialContact.locationURL} target="_blank" rel="noreferrer">
																<Location />
															</a>
														) : null}

														{SocialContact.phoneNumber ? (
															<a href={`tel:${SocialContact.phoneNumber}`}>
																<Phone />
															</a>
														) : null}

														{SocialContact.phoneNumber ? (
															<a href={`tel:${SocialContact.phoneNumber}`}>
																<span className="ps-1">{SocialContact.phoneNumber}</span>
															</a>
														) : null}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</motion.div>
			</Backdrop>
		</>
	);
};

export default Modal;
